/* Placement */
[data-amplify-authenticator] [data-amplify-container] {
  align-self: flex-start;
  margin-top: 3rem;
}

/* Tabs */
[data-amplify-authenticator] [role=tab] {
  display: none;
}

/* Email/Password area */
[data-amplify-authenticator-signin] > *:first-child ~ * {
  display: none;
}

/* Separator */
[data-amplify-authenticator-signin] [data-or-container],
[data-amplify-authenticator-signin] [data-or-container] + * {
  display: none;
}

/* Footer */
[data-amplify-authenticator-signin] + * {
  display: none;
}
